
<template>
    <v-card>
        <v-card-text>
            <v-row class="pl-5">
                <v-col cols="12">
                    <v-data-table :headers="zonesHeaders" :items="zones">
                        <template v-slot:item.actions="{ item }">
                            <v-btn color="secondary" icon>
                                <v-icon @click="openRenameDialog(item)">mdi-pencil-outline</v-icon>
                            </v-btn>
                            <v-btn color="primary" icon>
                                <v-icon @click="openConfigDialog(item)">mdi-cog-outline</v-icon>
                            </v-btn>
                            <v-btn color="error" icon>
                                <v-icon @click="deleteZone(item)">mdi-delete-outline</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-col>
                <v-col cols="12" class="pa-3 d-flex justify-end">
                    <v-btn color="primary" fab class="ml-3" @click="zoneCreationDialog = true">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-dialog v-model="zoneRenameDialog" scrollable persistent max-width="500px">
                <v-card>
                    <v-card-title class="headline">Renommer la zone</v-card-title>
                    <v-card-text>
                        <v-text-field label="Nom de la zone" v-model="zoneRenameName"></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="secondary" text @click="zoneRenameDialog = false">Annuler</v-btn>
                        <v-btn color="primary" text @click="renameZone">Renommer</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="zoneCreationDialog" scrollable persistent max-width="500px">
                <v-card>
                    <v-card-title class="headline">Ajouter une zone</v-card-title>
                    <v-card-text>
                        <v-text-field label="Nom de la zone" v-model="zoneCreationName"></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="secondary" text @click="zoneCreationDialog = false">Annuler</v-btn>
                        <v-btn color="primary" text @click="createZone">Ajouter</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <ZoneConfig ref="zoneConfigDialog"/>
        </v-card-text>
    </v-card>
</template>

<script>

import Swal from 'sweetalert2/dist/sweetalert2.js'

import Zone from '@/classes/Zone'

import ZoneConfig from '@/components/assets/zones/ZoneConfig'

export default {
    name: "ZoneAssetsList",
    components: {
        ZoneConfig
    },
    data() {
        return {
            unsub : [],
            zonesHeaders : [
                { text: 'Nom', value: 'name' },
                { text: '', value: 'actions', sortable: false, align: 'right' },
            ],
            zones : [],
            zoneRenameDialog : false,
            zoneRenameName : "",
            zoneCreationDialog : false,
            zoneCreationName : "",
        }
    },
    created() {
        this.unsub.push(Zone.listenAll((zones) => {
            this.zones = zones
            this.zones.sort((a, b) => (a.name > b.name) ? 1 : -1)
        }))
    },
    methods: {
        createZone() {
            if(this.zoneCreationName.trim() != ""){
                Zone.initializeOne(this.zoneCreationName).then((zone) => {
                    zone.save()
                    this.zoneCreationDialog = false;
                })
            }
        },
        openRenameDialog(zone) {
            this.zoneRenameSelectedZone = zone
            this.zoneRenameName = zone.name
            this.zoneRenameDialog = true
        },
        openConfigDialog(zone) {
            this.$refs.zoneConfigDialog.openDialog(zone)
        },
        renameZone() {
            this.zoneRenameSelectedZone.name = this.zoneRenameName
            this.zoneRenameSelectedZone.save()
            this.zoneRenameDialog = false
        },
        deleteZone(zone){
            Swal.fire({
                title: 'Etes vous sûr ?',
                text: 'Voulez vous vraiment supprimer cette zone ?',
                icon: 'question',
                confirmButtonText: 'Confirmer',
                showCancelButton: true,
                cancelButtonText: 'Annuler',
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    zone.delete()
                }
            })
        },
    },
    destroyed() {
        this.unsub.forEach((unsub) => {
            if(typeof unsub == "function"){
                unsub()
            }
        })
    },
}
</script>