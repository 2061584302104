<template>
    <span v-if="machine">
        <v-dialog v-model="configDialog" scrollable persistent max-width="600px">
            <v-card>
                <v-card-title class="headline" v-text="'Configurer la machine ( ' + machine.name + ' )'"></v-card-title>
                <v-card-text>
                    <v-row class="pl-5">
                        <v-col cols="12">
                            <v-text-field type="number" label="Temps de livraison" suffix="Période(s)" v-model="machine.delivery"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-select class="mb-5" :items="icons" item-value="index" v-model="machine.icon" label="Icone">
                                <template slot="item" slot-scope="data">
                                    <v-img class="py-10" contain height="auto" max-height="150px" :src="require(`@/assets/images/icons/machines/${data.item.file}`)"></v-img>
                                </template>
                                <template slot="selection" slot-scope="data">
                                    <v-img contain height="auto" max-height="150px" :src="require(`@/assets/images/icons/machines/${data.item.file}`)"></v-img>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field type="number" label="Nombre de postes de travail" suffix="Poste(s)" v-model="machine.workplaceAmount"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field type="number" label="Valeur brute" suffix="€" v-model="machine.grossValue"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field type="number" label="Frais de structure" suffix="€" v-model="machine.structuralCost"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field type="number" label="Budget de maintenance optimal" suffix="€" v-model="machine.optimalMaintenanceBudget"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field type="number" label="Durée d'amortissement" suffix="année(s)" v-model="machine.deprecationPeriods"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field type="number" label="Consomation electrique" suffix="W/Production" v-model="machine.conso"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field type="number" label="Emissions de CO2" suffix="g/Production" v-model="machine.carbonEmission"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field type="number" label="Perte a la revente" suffix="%" v-model="machine.saleLoss"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field type="number" label="Modificateur de production" suffix="%" v-model="machine.productionBoost"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field type="number" label="Coûts de R&D" suffix="€" v-model="machine.rndCost"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-switch label="Disponibilité" v-model="machine.available"></v-switch>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" text @click="configDialog = false">Annuler</v-btn>
                    <v-btn color="primary" text @click="save">Sauvegarder</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </span>
</template>

<script>
import assetsIcons from '@/config/assetsIcons';

import Machine from '@/classes/Machine'

export default {
    name: "MachineConfig",
    data() {
        return {
            configDialog: false,
            machine: null,
            icons: assetsIcons.machines,
        }
    },
    methods: {
        openDialog(machine) {
            this.machine = machine
            this.configDialog = true
        },
        save() {
            this.machine.save()
            this.configDialog = false
        },
    },
}
</script>