
<template>
    <v-card>
        <v-card-text>
            <v-row class="pl-5">
                <v-col cols="12">
                    <v-data-table :headers="productsHeaders" :items="products">
                        <template v-slot:item.actions="{ item }">
                            <v-btn color="secondary" icon>
                                <v-icon @click="openRenameDialog(item)">mdi-pencil-outline</v-icon>
                            </v-btn>
                            <v-btn color="primary" icon>
                                <v-icon @click="openConfigDialog(item)">mdi-cog-outline</v-icon>
                            </v-btn>
                            <v-btn color="error" icon>
                                <v-icon @click="deleteProduct(item)">mdi-delete-outline</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-col>
                <v-col cols="12" class="pa-3 d-flex justify-end">
                    <v-btn color="primary" fab class="ml-3" @click="productCreationDialog = true">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-dialog v-model="productRenameDialog" scrollable persistent max-width="500px">
                <v-card>
                    <v-card-title class="headline">Renommer le product</v-card-title>
                    <v-card-text>
                        <v-text-field label="Nom de la product" v-model="productRenameName"></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="secondary" text @click="productRenameDialog = false">Annuler</v-btn>
                        <v-btn color="primary" text @click="renameProduct">Renommer</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="productCreationDialog" scrollable persistent max-width="500px">
                <v-card>
                    <v-card-title class="headline">Ajouter un product</v-card-title>
                    <v-card-text>
                        <v-text-field label="Nom de la product" v-model="productCreationName"></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="secondary" text @click="productCreationDialog = false">Annuler</v-btn>
                        <v-btn color="primary" text @click="createProduct">Ajouter</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <ProductConfig ref="productConfigDialog"/>
        </v-card-text>
    </v-card>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'

import Product from '@/classes/Product'

import ProductConfig from '@/components/assets/products/ProductConfig'

export default {
    name: "ProductAssetsList",
    components: {
        ProductConfig
    },
    data() {
        return {
            unsub : [],
            productsHeaders : [
                { text: 'Nom', value: 'name' },
                { text: '', value: 'actions', sortable: false, align: 'right' },
            ],
            products : [],
            productRenameDialog : false,
            productRenameName : "",
            productCreationDialog : false,
            productCreationName : "",
        }
    },
    created() {
        this.unsub.push(Product.listenAll((products) => {
            this.products = products
            this.products.sort((a, b) => (a.name > b.name) ? 1 : -1)
        }))
    },
    methods: {
        createProduct() {
            if(this.productCreationName.trim() != ""){
                Product.initializeOne(this.productCreationName).then((product) => {
                    product.save()
                    this.productCreationDialog = false;
                })
            }
        },
        openRenameDialog(product) {
            this.productRenameSelectedProduct = product
            this.productRenameName = product.name
            this.productRenameDialog = true
        },
        openConfigDialog(product) {
            this.$refs.productConfigDialog.openDialog(product)
        },
        renameProduct() {
            this.productRenameSelectedProduct.name = this.productRenameName
            this.productRenameSelectedProduct.save()
            this.productRenameDialog = false
        },
        deleteProduct(product){
            Swal.fire({
                title: 'Etes vous sûr ?',
                text: 'Voulez vous vraiment supprimer ce produit ?',
                icon: 'question',
                confirmButtonText: 'Confirmer',
                showCancelButton: true,
                cancelButtonText: 'Annuler',
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    product.delete()
                }
            })
        },
    },
    destroyed() {
        this.unsub.forEach((unsub) => {
            if(typeof unsub == "function"){
                unsub()
            }
        })
    },
}
</script>