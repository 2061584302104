
<template>
    <v-card>
        <v-card-text>
            <v-row class="pl-5">
                <v-col cols="12">
                    <v-data-table :headers="formationsHeaders" :items="formations">
                        <template v-slot:item.actions="{ item }">
                            <v-btn color="secondary" icon>
                                <v-icon @click="openRenameDialog(item)">mdi-pencil-outline</v-icon>
                            </v-btn>
                            <v-btn color="primary" icon>
                                <v-icon @click="openConfigDialog(item)">mdi-cog-outline</v-icon>
                            </v-btn>
                            <v-btn color="error" icon>
                                <v-icon @click="deleteFormation(item)">mdi-delete-outline</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-col>
                <v-col cols="12" class="pa-3 d-flex justify-end">
                    <v-btn color="primary" fab class="ml-3" @click="formationCreationDialog = true">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-dialog v-model="formationRenameDialog" scrollable persistent max-width="500px">
                <v-card>
                    <v-card-title class="headline">Renommer le formation</v-card-title>
                    <v-card-text>
                        <v-text-field label="Nom de la formation" v-model="formationRenameName"></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="secondary" text @click="formationRenameDialog = false">Annuler</v-btn>
                        <v-btn color="primary" text @click="renameFormation">Renommer</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="formationCreationDialog" scrollable persistent max-width="500px">
                <v-card>
                    <v-card-title class="headline">Ajouter un formation</v-card-title>
                    <v-card-text>
                        <v-text-field label="Nom de la formation" v-model="formationCreationName"></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="secondary" text @click="formationCreationDialog = false">Annuler</v-btn>
                        <v-btn color="primary" text @click="createFormation">Ajouter</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <FormationConfig ref="formationConfigDialog"/>
        </v-card-text>
    </v-card>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'

import Formation from '@/classes/Formation'

import FormationConfig from '@/components/assets/formations/FormationConfig'

export default {
    name: "FormationAssetsList",
    components: {
        FormationConfig
    },
    data() {
        return {
            unsub : [],
            formationsHeaders : [
                { text: 'Nom', value: 'name' },
                { text: '', value: 'actions', sortable: false, align: 'right' },
            ],
            formations : [],
            formationRenameDialog : false,
            formationRenameName : "",
            formationCreationDialog : false,
            formationCreationName : "",
        }
    },
    created() {
        this.unsub.push(Formation.listenAll((formations) => {
            this.formations = formations
            this.formations.sort((a, b) => (a.name > b.name) ? 1 : -1)
        }))
    },
    methods: {
        createFormation() {
            if(this.formationCreationName.trim() != ""){
                Formation.initializeOne(this.formationCreationName).then((formation) => {
                    formation.save()
                    this.formationCreationDialog = false;
                })
            }
        },
        openRenameDialog(formation) {
            this.formationRenameSelectedFormation = formation
            this.formationRenameName = formation.name
            this.formationRenameDialog = true
        },
        openConfigDialog(formation) {
            this.$refs.formationConfigDialog.openDialog(formation)
        },
        renameFormation() {
            this.formationRenameSelectedFormation.name = this.formationRenameName
            this.formationRenameSelectedFormation.save()
            this.formationRenameDialog = false
        },
        deleteFormation(formation){
            Swal.fire({
                title: 'Etes vous sûr ?',
                text: 'Voulez vous vraiment supprimer ce produit ?',
                icon: 'question',
                confirmButtonText: 'Confirmer',
                showCancelButton: true,
                cancelButtonText: 'Annuler',
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    formation.delete()
                }
            })
        },
    },
    destroyed() {
        this.unsub.forEach((unsub) => {
            if(typeof unsub == "function"){
                unsub()
            }
        })
    },
}
</script>