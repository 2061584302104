<template>
    <span v-if="service">
        <v-dialog v-model="configDialog" scrollable persistent max-width="600px">
            <v-card>
                <v-card-title class="headline" v-text="'Configurer le service ( ' + service.name + ' )'"></v-card-title>
                <v-card-text>
                    <v-row class="pl-5">
                        <v-col cols="12">
                            <v-select class="mb-5" :items="icons" item-value="index" v-model="service.icon" label="Icone">
                                <template slot="item" slot-scope="data">
                                    <v-img class="py-10" contain height="auto" max-height="150px" :src="require(`@/assets/images/icons/services/${data.item.file}`)"></v-img>
                                </template>
                                <template slot="selection" slot-scope="data">
                                    <v-img contain height="auto" max-height="150px" :src="require(`@/assets/images/icons/services/${data.item.file}`)"></v-img>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field type="number" label="Part d'achat" suffix="%" v-model="service.purchaseShare"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field type="number" label="Prix min" suffix="€" v-model="service.minimalPrice"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field type="number" label="Prix max" suffix="€" v-model="service.maximalPrice"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field type="number" label="Taux de report" suffix="%" v-model="service.carryoverRate"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field type="number" label="Temps de production" suffix="heure(s)" v-model="service.productionTime"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field type="number" label="Coûts de production" suffix="€" v-model="service.productionCost"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field type="number" label="Frais de distribution" suffix="%" v-model="service.distributionCost"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field type="number" label="Coûts de R&D" suffix="%" v-model="service.rndCost"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" text @click="configDialog = false">Annuler</v-btn>
                    <v-btn color="primary" text @click="save">Sauvegarder</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </span>
</template>

<script>

import assetsIcons from '@/config/assetsIcons';

import Service from '@/classes/Service'

export default {
    name: "ServiceConfig",
    data() {
        return {
            configDialog: false,
            service: null,
            icons: assetsIcons.services
        }
    },
    methods: {
        openDialog(service) {
            this.service = service
            this.configDialog = true
        },
        save() {
            this.service.save()
            this.configDialog = false
        },
    },
}
</script>