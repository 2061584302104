<template>
    <span v-if="cv">
        <v-dialog v-model="configDialog" scrollable persistent max-width="600px">
            <v-card>
                <v-card-title class="headline"
                    v-text="'Configurer le cv ( ' + cv.lastname + ' ' + cv.firstname + ' )'"></v-card-title>
                <v-card-text>
                    <v-row class="pl-5">
                        <v-col cols="12">
                            <v-select :items="jobItems" v-model="cv.job" label="Poste"></v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field type="number" label="Salaire de base" suffix="€" v-model="cv.baseSalary"></v-text-field>
                        </v-col>
                        <v-col cols="12" v-if="cv.job == 'Salesman'">
                            <v-text-field type="number" label="Efficacité de vente" suffix="Point(s)" v-model="cv.sellingEfficiency"></v-text-field>
                        </v-col>
                        <v-col cols="12" v-if="cv.job != 'Salesman'">
                            <v-text-field type="number" label="Boost de productivité" suffix="%" v-model="cv.productivityBoost"></v-text-field>
                        </v-col>
                        <v-col cols="12" v-if="cv.job != 'Salesman'">
                            <v-text-field type="number" label="Capacité" :suffix="cv.job == 'ProductManager' ? 'Ouvrier(s)' : 'Employé(s)'" v-model="cv.staffCapacity"></v-text-field>
                        </v-col>
                        <v-col cols="12" class="d-flex align-center justify-center" v-if="hasFile">
                            <v-btn color="error" outlined @click="clearFileInput()">Supprimmer le CV</v-btn>
                        </v-col>
                        <v-col cols="12" class="d-flex align-center justify-center" v-else>
                            <v-file-input v-show="false" accept=".pdf" truncate-length="50" label="Fichier CV" v-model="cvFile" prepend-icon ref="fileInput"></v-file-input>
                            <v-btn color="primary" outlined @click="openFileInput()">Charger un nouveau CV</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" text @click="configDialog = false">Annuler</v-btn>
                    <v-btn color="primary" text @click="save">Sauvegarder</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </span>
</template>

<script>
import { deleteObject, getStorage, ref, uploadBytes } from "firebase/storage"

import Cv from '@/classes/Cv'

export default {
    name: "CvConfig",
    setup() {
        return {
            storage: getStorage(),
        }
    },
    data() {
        return {
            jobItems: [
                {
                    text: 'Vendeur',
                    value: 'Salesman',
                },
                {
                    text: 'Manager',
                    value: 'ServiceManager',
                },
                {
                    text: 'Chef de production',
                    value: 'ProductManager',
                },
            ],
            configDialog: false,
            cv: null,
            cvFile: null,
        }
    },
    computed: {
        hasFile() {
            return this.cvFile != null && this.cvFile != undefined
        },
    },
    methods: {
        clearFileInput() {
            deleteObject(ref(this.storage, this.cv.fileId))
            this.cv.removeFile()
            this.cvFile = null
        },
        openFileInput() {
            this.$refs.fileInput.$refs.input.click()
        },
        openDialog(cv) {
            this.cv = cv
            this.cvFile = cv.fileId
            this.configDialog = true
        },
        async save() {
            if(this.cvFile != null && this.cvFile != undefined && this.cvFile.constructor.name == 'File'){
                let name = this.cv.lastname.trim() + '_' + this.cv.firstname.trim() + '.pdf'
                let fileRef = ref(this.storage, 'cv/' + name)
                await uploadBytes(fileRef, this.cvFile)
                this.cv.fileId = 'cv/' + name
            }

            if(this.cv.job == 'Salesman'){
                this.cv.productivityBoost = 0
                this.cv.staffCapacity = 0
            } else {
                this.cv.sellingEfficiency = 0
            }
            this.cv.save()
            this.configDialog = false
        },
    },
}
</script>