<template>
    <span v-if="profile.role != 'User'">
        <v-card class="mt-3 pa-3">
            <v-card-title primary-title class="justify-center">
                <h1 class="text-center mb-3">Assets</h1>
            </v-card-title>
            <v-card-text>
                    <v-tabs v-model="tab" centered>
                        <v-tab>Zones</v-tab>
                        <v-tab>Produits</v-tab>
                        <v-tab>Services</v-tab>
                        <v-tab>machines</v-tab>
                        <v-tab>CVs</v-tab>
                        <v-tab>Formations</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab">
                        <v-tab-item>
                            <ZoneAssetsList/>
                        </v-tab-item>
                        <v-tab-item>
                            <ProductAssetsList/>
                        </v-tab-item>
                        <v-tab-item>
                            <ServiceAssetsList/>
                        </v-tab-item>
                        <v-tab-item>
                            <MachineAssetsList/>
                        </v-tab-item>
                        <v-tab-item>
                            <CvAssetsList/>
                        </v-tab-item>
                        <v-tab-item>
                            <FormationAssetsList/>
                        </v-tab-item>
                    </v-tabs-items>
            </v-card-text>
        </v-card>
    </span>
</template>

<script>
import ZoneAssetsList from "@/components/assets/zones/ZoneAssetsList.vue"
import ProductAssetsList from "@/components/assets/products/ProductAssetsList.vue"
import ServiceAssetsList from "@/components/assets/services/ServiceAssetsList.vue"
import MachineAssetsList from '../components/assets/machines/MachineAssetsList.vue'
import CvAssetsList from '../components/assets/cv/CvAssetsList.vue'
import FormationAssetsList from '../components/assets/formations/FormationAssetsList.vue'

export default {
    name: "Assets",
    props: ["user", "profile"],
    components: {
        ZoneAssetsList,
        ProductAssetsList,
        ServiceAssetsList,
        MachineAssetsList,
        CvAssetsList,
        FormationAssetsList,
    },
    data() {
        return {
            tab: null,
        }
    },
}
</script>
