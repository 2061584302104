<template>
    <span v-if="zone">
        <v-dialog v-model="configDialog" scrollable persistent max-width="600px">
            <v-card>
                <v-card-title class="headline" v-text="'Configurer la zone ( ' + zone.name + ' )'"></v-card-title>
                <v-card-text>
                    <v-row class="pl-5">
                        <v-col cols="12">
                            <v-text-field type="number" label="Indice conjoncturel" suffix="%" v-model="zone.conjuncturalIndex"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field type="number" label="Habitants par entreprises" suffix="Habitant(s)" v-model="zone.residentByCompany"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field type="number" label="Pouvoir d'achat" suffix="€" v-model="zone.purchasingPower"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field type="number" label="Coûts spécifiques" suffix="€" v-model="zone.specificCosts"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field type="number" label="Surcoût de distribution" suffix="%" v-model="zone.overcost"></v-text-field>
                        </v-col>
                        <h3>Transport :</h3>
                        <v-col cols="12">
                            <v-switch label="Camion" v-model="zone.transports.car.available"></v-switch>
                            <span v-if="zone.transports.car.available">
                              <v-text-field type="number" label="Coût" suffix="%" v-model="zone.transports.car.cost"></v-text-field>
                              <v-text-field type="number" label="Emissions de CO2" suffix="g" v-model="zone.transports.car.carbonEmission"></v-text-field>
                            </span>
                        </v-col>
                        <v-col cols="12">
                            <v-switch label="Train" v-model="zone.transports.train.available"></v-switch>
                            <span v-if="zone.transports.train.available">
                              <v-text-field type="number" label="Coût" suffix="%" v-model="zone.transports.train.cost"></v-text-field>
                              <v-text-field type="number" label="Emissions de CO2" suffix="g" v-model="zone.transports.train.carbonEmission"></v-text-field>
                            </span>
                        </v-col>
                        <v-col cols="12">
                            <v-switch label="Bateau" v-model="zone.transports.boat.available"></v-switch>
                            <span v-if="zone.transports.boat.available">
                              <v-text-field type="number" label="Coût" suffix="%" v-model="zone.transports.boat.cost"></v-text-field>
                              <v-text-field type="number" label="Emissions de CO2" suffix="g" v-model="zone.transports.boat.carbonEmission"></v-text-field>
                            </span>
                        </v-col>
                        <v-col cols="12">
                            <v-switch label="Avion" v-model="zone.transports.plane.available"></v-switch>
                            <span v-if="zone.transports.plane.available">
                              <v-text-field type="number" label="Coût" suffix="%" v-model="zone.transports.plane.cost"></v-text-field>
                              <v-text-field type="number" label="Emissions de CO2" suffix="g" v-model="zone.transports.plane.carbonEmission"></v-text-field>
                            </span>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" text @click="configDialog = false">Annuler</v-btn>
                    <v-btn color="primary" text @click="save">Sauvegarder</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </span>
</template>

<script>
import Zone from '@/classes/Zone'

export default {
    name: "ZoneConfig",
    data() {
        return {
            configDialog: false,
            zone: null,
        }
    },
    methods: {
        openDialog(zone) {
            this.zone = zone
            this.configDialog = true
        },
        save() {
            this.zone.save()
            this.configDialog = false
        },
    },
}
</script>