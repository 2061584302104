
<template>
    <v-card>
        <v-card-text>
            <v-row class="pl-5">
                <v-col cols="12">
                    <v-data-table :headers="cvsHeaders" :items="cvs">
                        <template v-slot:item.actions="{ item }">
                            <v-btn color="accent" icon v-if="item.url != null">
                                <v-icon @click="openCvFile(item)">mdi-eye-outline</v-icon>
                            </v-btn>
                            <v-btn color="secondary" icon>
                                <v-icon @click="openRenameDialog(item)">mdi-pencil-outline</v-icon>
                            </v-btn>
                            <v-btn color="primary" icon>
                                <v-icon @click="openConfigDialog(item)">mdi-cog-outline</v-icon>
                            </v-btn>
                            <v-btn color="error" icon>
                                <v-icon @click="deleteCv(item)">mdi-delete-outline</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-col>
                <v-col cols="12" class="pa-3 d-flex justify-end">
                    <v-btn color="primary" fab class="ml-3" @click="cvCreationDialog = true">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-dialog v-model="cvRenameDialog" scrollable persistent max-width="500px">
                <v-card>
                    <v-card-title class="headline">Renommer le cv</v-card-title>
                    <v-card-text>
                        <v-text-field label="Prenom" v-model="cvRenameFirstame"></v-text-field>
                        <v-text-field label="Nom" v-model="cvRenameLastame"></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="secondary" text @click="cvRenameDialog = false">Annuler</v-btn>
                        <v-btn color="primary" text @click="renameCv">Renommer</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="cvCreationDialog" scrollable persistent max-width="500px">
                <v-card>
                    <v-card-title class="headline">Ajouter un cv</v-card-title>
                    <v-card-text>
                        <v-text-field label="Nom" v-model="cvCreationFirstname"></v-text-field>
                        <v-text-field label="Prenom" v-model="cvCreationLastname"></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="secondary" text @click="cvCreationDialog = false">Annuler</v-btn>
                        <v-btn color="primary" text @click="createCv">Ajouter</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <CvConfig ref="cvConfigDialog"/>
        </v-card-text>
    </v-card>
</template>

<script>
import { getStorage, ref, getDownloadURL } from "firebase/storage"
import Swal from 'sweetalert2/dist/sweetalert2.js'

import Cv from '@/classes/Cv'

import CvConfig from '@/components/assets/cv/CvConfig'

export default {
    name: "CvAssetsList",
    components: {
        CvConfig
    },
    setup() {
        return {
            storage : getStorage(),
        }
    },
    data() {
        return {
            unsub : [],
            cvsHeaders : [
                { text: 'Nom', value: 'lastname' },
                { text: 'Prenom', value: 'firstname' },
                { text: '', value: 'actions', sortable: false, align: 'right' },
            ],
            cvs : [],
            cvRenameDialog : false,
            cvRenameFirstame : "",
            cvRenameLastame : "",
            cvCreationDialog : false,
            cvCreationFirstname : "",
            cvCreationLastname : "",
        }
    },
    async created() {
        this.unsub.push(Cv.listenAll(async (cvs) => {
            let tmp_cvs = []
            cvs.forEach(async cv => {
                if(cv.fileId != null && cv.fileId.trim() != ""){
                    let fileRef = ref(this.storage, cv.fileId) 
                    let url = await getDownloadURL(fileRef)
                    cv.url = url
                    tmp_cvs.push(cv)
                }else{
                    cv.url = null
                    tmp_cvs.push(cv)
                }
            })
            tmp_cvs.sort((a, b) => (a.lastname > b.lastname) ? 1 : -1)
            this.cvs = tmp_cvs
        }))
    },
    methods: {
        createCv() {
            if(this.cvCreationFirstname.trim() != "" && this.cvCreationLastname.trim() != ""){
                Cv.initializeOne(this.cvCreationFirstname, this.cvCreationLastname).then((cv) => {
                    cv.save()
                    this.cvCreationDialog = false;
                })
            }
        },
        openCvFile(cv) {
            if(cv.url != null){
                window.open(cv.url, '_blank')
            }
        },
        openRenameDialog(cv) {
            this.cvRenameSelectedCv = cv
            this.cvRenameFirstame = cv.firstname
            this.cvRenameLastame = cv.lastname
            this.cvRenameDialog = true
        },
        openConfigDialog(cv) {
            this.$refs.cvConfigDialog.openDialog(cv)
        },
        renameCv() {
            this.cvRenameSelectedCv.firstname = this.cvRenameFirstame
            this.cvRenameSelectedCv.lastname = this.cvRenameLastame
            this.cvRenameSelectedCv.save()
            this.cvRenameDialog = false
        },
        deleteCv(cv){
            Swal.fire({
                title: 'Etes vous sûr ?',
                text: 'Voulez vous vraiment supprimer ce cv ?',
                icon: 'question',
                confirmButtonText: 'Confirmer',
                showCancelButton: true,
                cancelButtonText: 'Annuler',
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    cv.delete()
                }
            })
        },
    },
    destroyed() {
        this.unsub.forEach((unsub) => {
            if(typeof unsub == "function"){
                unsub()
            }
        })
    },
}
</script>