
<template>
    <v-card>
        <v-card-text>
            <v-row class="pl-5">
                <v-col cols="12">
                    <v-data-table :headers="servicesHeaders" :items="services">
                        <template v-slot:item.actions="{ item }">
                            <v-btn color="secondary" icon>
                                <v-icon @click="openRenameDialog(item)">mdi-pencil-outline</v-icon>
                            </v-btn>
                            <v-btn color="primary" icon>
                                <v-icon @click="openConfigDialog(item)">mdi-cog-outline</v-icon>
                            </v-btn>
                            <v-btn color="error" icon>
                                <v-icon @click="deleteService(item)">mdi-delete-outline</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-col>
                <v-col cols="12" class="pa-3 d-flex justify-end">
                    <v-btn color="primary" fab class="ml-3" @click="serviceCreationDialog = true">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-dialog v-model="serviceRenameDialog" scrollable persistent max-width="500px">
                <v-card>
                    <v-card-title class="headline">Renommer le service</v-card-title>
                    <v-card-text>
                        <v-text-field label="Nom de la service" v-model="serviceRenameName"></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="secondary" text @click="serviceRenameDialog = false">Annuler</v-btn>
                        <v-btn color="primary" text @click="renameService">Renommer</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="serviceCreationDialog" scrollable persistent max-width="500px">
                <v-card>
                    <v-card-title class="headline">Ajouter un service</v-card-title>
                    <v-card-text>
                        <v-text-field label="Nom de la service" v-model="serviceCreationName"></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="secondary" text @click="serviceCreationDialog = false">Annuler</v-btn>
                        <v-btn color="primary" text @click="createService">Ajouter</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <ServiceConfig ref="serviceConfigDialog"/>
        </v-card-text>
    </v-card>
</template>

<script>

import Swal from 'sweetalert2/dist/sweetalert2.js'

import Service from '@/classes/Service'

import ServiceConfig from '@/components/assets/services/ServiceConfig'

export default {
    name: "ServiceAssetsList",
    components: {
        ServiceConfig
    },
    data() {
        return {
            unsub : [],
            servicesHeaders : [
                { text: 'Nom', value: 'name' },
                { text: '', value: 'actions', sortable: false, align: 'right' },
            ],
            services : [],
            serviceRenameDialog : false,
            serviceRenameName : "",
            serviceCreationDialog : false,
            serviceCreationName : "",
        }
    },
    created() {
        this.unsub.push(Service.listenAll((services) => {
            this.services = services
            this.services.sort((a, b) => (a.name > b.name) ? 1 : -1)
        }))
    },
    methods: {
        createService() {
            if(this.serviceCreationName.trim() != ""){
                Service.initializeOne(this.serviceCreationName).then((service) => {
                    service.save()
                    this.serviceCreationDialog = false;
                })
            }
        },
        openRenameDialog(service) {
            this.serviceRenameSelectedService = service
            this.serviceRenameName = service.name
            this.serviceRenameDialog = true
        },
        openConfigDialog(service) {
            this.$refs.serviceConfigDialog.openDialog(service)
        },
        renameService() {
            this.serviceRenameSelectedService.name = this.serviceRenameName
            this.serviceRenameSelectedService.save()
            this.serviceRenameDialog = false
        },
        deleteService(service){
            Swal.fire({
                title: 'Etes vous sûr ?',
                text: 'Voulez vous vraiment supprimer ce service ?',
                icon: 'question',
                confirmButtonText: 'Confirmer',
                showCancelButton: true,
                cancelButtonText: 'Annuler',
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    service.delete()
                }
            })
        },
    },
    destroyed() {
        this.unsub.forEach((unsub) => {
            if(typeof unsub == "function"){
                unsub()
            }
        })
    },
}
</script>