<template>
    <span v-if="formation">
        <v-dialog v-model="configDialog" scrollable persistent max-width="600px">
            <v-card>
                <v-card-title class="headline" v-text="'Configurer le produit ( ' + formation.name + ' )'"></v-card-title>
                <v-card-text>
                    <v-row class="pl-5">
                        <v-col cols="12">
                            <v-select :items="jobItems" v-model="formation.job" label="Poste"></v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field type="number" label="Cout" suffix="€" v-model="formation.cost"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field type="number" label="Durée" suffix="jour(s)" v-model="formation.duration"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field type="number" label="Augmentation d'indice social" suffix="%" v-model="formation.salaryIncrease"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field type="number" label="Augmentation d'efficacité" suffix="%" v-model="formation.efficiencyIncrease"></v-text-field>
                        </v-col>
                        <v-col cols="12" v-if="formation.job != 'Salesman'">
                            <v-text-field type="number" label="Augmentation de capacité" :suffix="formation.job == 'ProductManager' ? 'Ouvrier(s)' : 'Employé(s)'" v-model="formation.staffCapacityIncrease"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" text @click="configDialog = false">Annuler</v-btn>
                    <v-btn color="primary" text @click="save">Sauvegarder</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </span>
</template>

<script>
import Product from '@/classes/Product'

export default {
    name: "FormationConfig",
    data() {
        return {
            jobItems: [
                {
                    text: 'Vendeur',
                    value: 'Salesman',
                },
                {
                    text: 'Manager',
                    value: 'ServiceManager',
                },
                {
                    text: 'Chef de production',
                    value: 'ProductManager',
                },
            ],
            configDialog: false,
            formation: null,
        }
    },
    methods: {
        openDialog(formation) {
            this.formation = formation
            this.configDialog = true
        },
        save() {
            if(this.formation.job == 'Salesman'){
                this.formation.staffCapacityIncrease = 0
            }
            this.formation.save()
            this.configDialog = false
        },
    },
}
</script>